<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-data-table :items="pendientesFacturacion" :headers="pendientesFacturacionHeader" />
      </v-col>
    </v-row>
  </v-container >
</template>

<script>
  export default {
    data() {
      return {
        pendientesFacturacion: [],
        pendientesFacturacionHeader: [
          { text: 'Comercial', value: 'comercial' },
          { text: 'Nº Estudios', value: 'nEstudios' },
          { text: 'Importe total', value: 'importe' },
        ],
      }
    }
  }
</script>